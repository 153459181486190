import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      backgroundGradient: string
      reverseGradient: string
      popupGradient: string
      white: string
      black: string
      coral: string
      green: string
      dodgerBlue: string
      opacityDodgerBlue: string
      opacityCoral: string
      opacityGreen: string
      darkBlue: string
      divider1: string
      divider2: string
      superRareJade: string
      neutral: string
      transparentWhite: string
      purpure: string
      tabsColor: string
      tooltipGradient: string
      dateColor: string
      iconColor: string
      paleGreen: string
      coralBlush: string
      lavender: string
      orange: string
    }
    customShadows: {
      lightShadow: string
      heavyShadow: string
    }
  }

  interface ThemeOptions {
    color?: {
      backgroundGradient?: string
      reverseGradient?: string
      popupGradient?: string
      white?: string
      black?: string
      coral?: string
      green?: string
      dodgerBlue?: string
      opacityDodgerBlue?: string
      opacityCoral?: string
      opacityGreen?: string
      darkBlue?: string
      divider1?: string
      divider2?: string
      superRareJade?: string
      neutral?: string
      transparentWhite?: string
      purpure?: string
      tabsColor?: string
      tooltipGradient?: string
      dateColor?: string
      iconColor?: string
      paleGreen?: string
      coralBlush?: string
      lavender?: string
      orange?: string
    }
    customShadows?: {
      lightShadow?: string
      heavyShadow?: string
    }
  }

  interface TypographyVariants {
    number: React.CSSProperties
    mainTitle: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    number?: React.CSSProperties
    mainTitle?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    number: true
    mainTitle: true
  }
}

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          color: '#fff',
          height: '60px',
        },
        popper: {
          backgroundColor: '#fff',
        },
        paper: {
          backgroundColor: '#fff',
        },
        groupUl: {
          backgroundColor: '#fff',
        },
        groupLabel: {
          backgroundColor: '#fff',
        },
        option: {
          color: '#000',
        },
        endAdornment: {
          button: {
            color: 'inherit',
          },
          color: '#fff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#fff',
          height: '60px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#00a79c',
    },
    error: {
      main: '#da6c64',
    },
    background: {
      default: '#1E1F5F',
      paper: '#0C112A',
    },
  },

  typography: {
    fontFamily: '"Source Sans 3"',
    allVariants: {
      color: '#fff',
      opacity: 0.9,
    },
    number: {
      fontSize: '2.625rem',
      fontFamily: 'Bebas Neue',
      color: '#fff',
      opacity: 0.9,
    },
    mainTitle: {
      fontSize: '1.875rem',
      color: '#fff',
      opacity: 0.9,
    },
  },
  color: {
    backgroundGradient: 'linear-gradient(90deg, #141627 0%, #1B1C5B 100%)',
    reverseGradient: 'linear-gradient(90deg, #1B1C5B 0%, #141627 100%)',
    tooltipGradient:
      'linear-gradient(180deg, rgba(24, 160, 251, 0.00) 0%, rgba(24, 160, 251, 0.51) 100%), #FFF',
    popupGradient:
      'linear-gradient(180deg, rgba(24, 160, 251, 0.00) 0%, rgba(24, 160, 251, 0.51) 33.5%), #FFF',
    white: '#fff',
    coral: '#FE6779',
    green: '#1ADBC2',
    black: '#000',
    dodgerBlue: '#18A0FB',
    opacityDodgerBlue: 'rgba(24, 160, 251, 0)',
    opacityCoral: 'rgba(254, 103, 121, 0.1)',
    opacityGreen: 'rgba(26, 219, 194, 0.1)',
    darkBlue: '#15172B',
    divider1: 'rgba(153, 153, 153, 0.1)',
    divider2: 'rgba(27, 28, 87, 0.1)',
    superRareJade: '#1BB9B0',
    neutral: 'rgba(254, 103, 121, 0)',
    transparentWhite: 'rgba(255, 255, 255, 0.1)',
    purpure: '#606EEC',
    tabsColor: '#232569',
    dateColor: '#6F707D',
    iconColor: '#151728',
    paleGreen: '#1BDCC3',
    coralBlush: '#FF687A',
    lavender: '#B867FF',
    orange: '#F79E55',
  },
  customShadows: {
    lightShadow: '0px 3.5px 5.5px 0px #00000005',
    heavyShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
  },
})
