import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

const cache = new InMemoryCache()

const fileUploadLink = createUploadLink({
  uri: '/graphql',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Apollo-Require-Preflight': 'true',
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ extensions, message }) => {
      const { code } = extensions ?? {}
      console.info(`[GraphQL error]: Message: ${message}, Code: ${code}`)
      if (
        code === 'UNAUTHENTICATED' &&
        !window.location.pathname.includes('/sign-in')
      ) {
        window.location.replace('/sign-in')
      }
    })
  if (networkError) console.info(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  cache,
  link: concat(errorLink, fileUploadLink as unknown as ApolloLink),
  credentials: 'same-origin',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  shouldBatch: true,
} as ApolloClientOptions<unknown>)

export default client
