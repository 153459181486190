import { ApolloProvider } from '@apollo/client'
import { LicenseInfo } from '@mui/x-license'
import { ScrollToTop } from 'Components'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import apolloClient from './Graphql/index'
import { DisplayModeProvider } from './Providers/DisplayModeProvider'
import './global.scss'

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_PRO_LICENSE)

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DisplayModeProvider>
        <ApolloProvider client={apolloClient}>
          <ScrollToTop />
          <App />
        </ApolloProvider>
      </DisplayModeProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
